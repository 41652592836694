import { Link } from "react-router-dom";
import win from "../assets/icons8-confetti-96.png";
import lose from "../assets/icons8-sad-96.png";
import "../Styles/PopUp.css";

const { REACT_APP_CONTACT_URL } = process.env;

interface ModalProps {
  score: number;
}

export default function Modal({ score }: ModalProps) {
  return (
    <div className="popup">
      <div className="modal  ">
        <div className="overlay"></div>
        <div className="modal-content flex flex-col items-center justify-center gap-y-4 w-72 md:w-80 h-auto p-8">
          <div>
            {score === 5 ? (
              <img className=" h-20 lg:h-28" alt="win" src={win}></img>
            ) : (
              <img className=" h-20 lg:h-28" alt="lose" src={lose}></img>
            )}
          </div>
          {score === 5 ? (
            <h2 className=" text-2xl lg:text-3xl font-semibold">
              Congratulation!
            </h2>
          ) : (
            <h2 className=" text-2xl lg:text-3xl font-semibold">
              Sorry, you lost!
            </h2>
          )}
          {score !== 0 &&
            (score === 5 ? (
              <p className=" text-xl g:text-2xl">
                You were able to find all the differences
              </p>
            ) : (
              <p className="text-xl lg:text-2xl">
                You could only find {score} differences
              </p>
            ))}
          <Link to={"/"}>
            {" "}
            <button className="button rounded-full">OK</button>
          </Link>
          <a
            href={REACT_APP_CONTACT_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="button rounded-full uppercase">
              {" "}
              Contact Us
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
