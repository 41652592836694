import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import logo from "../assets/workplay-logo-with-shadow.png";

const { REACT_APP_LOGO_URL } = process.env;

interface HomeProps {}

const Home: React.FC<HomeProps> = ({}) => {
  const isMobile = useMediaQuery({ maxWidth: 750 });

  return (
    <div className="element  flex flex-col items-center gap-y-8 py-12 px-5">
      <a href={REACT_APP_LOGO_URL} target="_blank" rel="noopener noreferrer">
        <img src={logo} className="h-10 cursor-pointer" alt="not found" />
      </a>
      <h1 className="text-black  text-2xl lg:text-3xl font-semibold">
        Welcome to Spot the difference game
      </h1>
      {!isMobile ? (
        <p className=" text-sm lg:text-xl text-black font-semibold  lg:px-96 ">
          You have two images displayed <br /> the left image is the original,
          and the image <br /> on the right contains five differences that you
          need to spot. <br /> Best of luck finding them!
        </p>
      ) : (
        <p className=" text-sm lg:text-xl text-black font-semibold px-2 lg:px-96 ">
          You have two images displayed <br /> the above image is the original,
          and the on the below contains five differences that you need to spot.{" "}
          <br /> Best of luck finding them!
        </p>
      )}
      <Link to={"game"}>
        {" "}
        <button className=" button rounded-full  p-4 font-semibold text-black">
          START
        </button>
      </Link>
    </div>
  );
};

export default Home;
