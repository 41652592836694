import { useState, useEffect } from "react";

import PopUp from "../Components/PopUp";
import original from "../assets/original.png";
import diff from "../assets/diff.png";
import logo from "../assets/workplay-logo-with-shadow.png";
import timer from "../assets/deadline.png";

import "../Styles/Game.css";

const { REACT_APP_LOGO_URL } = process.env;

interface GameProps {}

const areaCoordinates = [
  { coords: "63,176,27", id: "witch", className: "object-1" },
  { coords: "37,470,35", id: "hen", className: "object-3" },
  { coords: "190,250,13", id: "window", className: "object-2" },
  { coords: "471,414,21", id: "bush", className: "object-4" },
  { coords: "356,136,22", id: "crow", className: "object-5" },
  { coords: "49,139,26", id: "witch", className: "object-1" },
  { coords: "28,373,27", id: "hen", className: "object-3" },
  { coords: "152,202,12", id: "window", className: "object-2" },
  { coords: "375,332,19", id: "bush", className: "object-4" },
  { coords: "282,109,16", id: "crow", className: "object-5" },
  { coords: "37,106,19", id: "witch", className: "object-1" },
  { coords: "22,279,22", id: "hen", className: "object-3" },
  { coords: "116,150,9", id: "window", className: "object-2" },
  { coords: "281,248,15", id: "bush", className: "object-4" },
  { coords: "30,84,18", id: "witch", className: "object-1" },
  { coords: "18,232,18", id: "hen", className: "object-3" },
  { coords: "94,124,10", id: "window", className: "object-2" },
  { coords: "235,207,11", id: "bush", className: "object-4" },
  { coords: "178,72,14", id: "crow", className: "object-5" },
  { coords: "211,80,14", id: "crow", className: "object-5" },
  { coords: "28,82,15", id: "witch", className: "object-1" },
  { coords: "16,212,18", id: "hen", className: "object-3" },
  { coords: "86,117,8", id: "window", className: "object-2" },
  { coords: "217,192,11", id: "bush", className: "object-4" },
  { coords: "163,64,11", id: "crow", className: "object-5" },
];

const Game: React.FC<GameProps> = ({}) => {
  const [score, setScore] = useState<number>(0);
  const [seconds, setSeconds] = useState(60); // Set the initial time in seconds (1.5 minutes = 90 seconds)
  const [Win, setWin] = useState(false);
  const [Lose, setLose] = useState(false);

  // appear popUp after 2 seconds
  useEffect(() => {
    setTimeout(() => {
      if (score === 5) {
        setWin(true);
      } else if (score < 5 && seconds === 0) {
        setLose(true);
      }
    }, 1000);
  }, [score, seconds]);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds: number): number => {
        if (prevSeconds > 0 && score !== 5) {
          return prevSeconds - 1; // Decrement seconds
        } else if (prevSeconds === 0 && score === 5) {
          clearInterval(timer); // Stop the timer
        }

        return prevSeconds;
      });
    }, 1000); // Set interval to run every second (1000ms)

    return () => clearInterval(timer); // Clean up the interval on unmount
  }, [score]); // Adding 'score' as a dependency

  const displayTime = (): string => {
    const minutes: number = Math.floor(seconds / 60);
    const remainingSeconds: number = seconds % 60;
    return ` ${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handleAreaClick = (id: string, className: string) => {
    setScore(score + 1);
    document.getElementById(id)?.classList.add(className);
  };

  return (
    <div className="element  flex flex-col py-8  md:py-12 items-center  gap-y-4  md:gap-y-6 ">
      <a href={REACT_APP_LOGO_URL} target="_blank" rel="noopener noreferrer">
        <img
          src={logo}
          className="h-10 cursor-pointer"
          alt="Workplay Studio Pvt Ltd."
        />
      </a>
      <div className="flex flex-row  text-center gap-x-8 md:gap-x-40 text-l  md:text-xl font-bold ">
        <div className="flex flex-row w-28  items-center ">
          <img src={timer} className=" h-6 lg:h-8 " alt="hour glass" />{" "}
          <h1> - {displayTime()}</h1>
        </div>
        <div className="pt-1 w-30">
          {" "}
          <h1>SCORE - {score}/5</h1>
        </div>
      </div>
      <div className="flex  flex-col md:flex-row  gap-y-2 sm:gap-x-4 md:gap-x-8 lg:gap-x-16">
        <div className="w-auto border-4 lg:border-8 border-yellow-500">
          {" "}
          <img
            src={original}
            className="image w-auto"
            alt="Workplay studio Pvt  Ltd."
          />
        </div>
        <div className="w-auto relative cursor-pointer border-4 lg:border-8 border-yellow-500">
          {" "}
          <img
            src={diff}
            className="image w-auto "
            alt=" workplay studios"
            useMap="#image-map"
          />
          <map name="image-map">
            {areaCoordinates.map(({ coords, id, className }) => (
              <area
                key={id}
                coords={coords}
                onClick={() => handleAreaClick(id, className)}
                shape="circle"
                alt={id}
              />
            ))}
          </map>
          <div id="witch" className=" absolute">
            {" "}
          </div>
          <div id="window" className="  absolute">
            {" "}
          </div>
          <div id="hen" className="  absolute">
            {" "}
          </div>
          <div id="bush" className="  absolute">
            {" "}
          </div>
          <div id="crow" className="  absolute">
            {" "}
          </div>
        </div>
      </div>
      {Win && <PopUp score={score}></PopUp>}
      {Lose && <PopUp score={score}></PopUp>}
    </div>
  );
};

export default Game;
