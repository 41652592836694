import { createRoot } from "react-dom/client";
import { BrowserRouter,Route,Routes } from "react-router-dom";

import Home from './Components/Home';
import Game from "./Components/Game";
import './App.css';

function App() {
  return (
    <div className="App">
<BrowserRouter >
      <Routes>
        <Route path="/"  element={<Home></Home>} />
        <Route path="game"  element={<Game></Game>} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
